import { BreadCrumbs } from '@/components/UIParts/Breadcrumbs'
import { Button } from '@/components/UIParts/Button'
import { metaFactory } from '@/hooks/useMeta'
import { pagesPath } from '@/lib/$path'
import { NextLink } from '@/lib/next'

export const getStaticProps = async () => {
  const meta = metaFactory({
    title: '',
    description: '',
  })

  return {
    props: { meta },
  }
}

export const Page = () => {
  return (
    <section className="pt-16 md:pt-[7.375rem]">
      <div className="container">
        <BreadCrumbs
          lists={[
            {
              label: '404エラー',
            },
          ]}
          className="mb-14 md:mb-20"
        />
        <div className="mb-36 grid place-content-center md:mb-[12rem]">
          <div className="text-center">
            <div className="mb-10 text-sm font-bold leading-loose md:text-base md:leading-loose">
              大変申し訳ございません。
              <br />
              お探しのページは見つかりまでした。
            </div>
            <NextLink href={pagesPath.$url()} passHref>
              <Button as="a" data-size="medium" data-theme="secondary">
                TOPにもどる
              </Button>
            </NextLink>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Page
