import { memo } from 'react'

import { TruncateText } from '@/hooks/useUtils'
import { pagesPath } from '@/lib/$path'
import { NextLink } from '@/lib/next'

import styles from './Breadcrumbs.module.scss'

type BreadCrumbsProps = {
  className?: string
  lists: {
    label: string
    href?: string
  }[]
}

export const BreadCrumbs = memo<BreadCrumbsProps>(({ lists, className }) => {
  return (
    <nav>
      <ul aria-label="breadcrumb" className={`${styles.breadcrumb} ${className}`}>
        <li>
          <NextLink href={pagesPath.$url()} passHref>
            <a aria-label="トップ">トップ</a>
          </NextLink>
        </li>
        {lists.map((item, index) => (
          <li key={index} className={styles.breadcrumb__item}>
            {lists.length - 1 !== index ? (
              <NextLink href={item.href} passHref>
                <a>{item.label}</a>
              </NextLink>
            ) : (
              <span aria-current="page">
                <TruncateText text={item.label} />
              </span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  )
})
